<!--门禁模块--滞留人员显示-->
<template>
  <div
    id="strandedPersonnel"
    v-loading="loading"
    :element-loading-text="$t('strandedPersonnel.a1')"
  >
    <div class="header">
      <my-headertitle>{{ $t("strandedPersonnel.a2") }}</my-headertitle>
      <div class="header-inquire">
        <div>
          <p>{{ $t("strandedPersonnel.a3") }}</p>
          <a-cascader
            style="width: 250px"
            :options="options"
            change-on-select
            v-model="select"
            :allowClear="false"
            :field-names="{
              label: 'title',
              value: 'key',
              children: 'children',
            }"
          />
        </div>
        <div>
          <a-button type="primary" style="width: 120px" @click="inquire">
            {{ $t("strandedPersonnel.a4") }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="main" ref="main" v-table-size="tableSize">
      <my-tabletitle>
        {{ $t("strandedPersonnel.a5") }}
        <template v-slot:name>
          <a-popover placement="bottomRight">
            <template slot="content">
              <a-row style="width: 350px">
                <a-col
                  v-for="(item, index) in displayContent"
                  :key="index"
                  :span="12"
                >
                  <a-checkbox :disabled="item.disabled" v-model="item.select">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </template>
            <template slot="title">
              <span>{{ $t("alarm.a50") }}</span>
            </template>
            <a-button type="primary" size="small">
              {{ $t("alarm.a50") }}
            </a-button>
          </a-popover>
        </template>
      </my-tabletitle>
      <a-table
        :columns="column"
        :dataSource="tableDate"
        :scroll="size"
        :pagination="pagination"
        :rowKey="(record) => record.acsId"
      >
        <template slot="chaozuo" slot-scope="text, record">
          <a-button type="primary" @click="() => Delete(record)">{{
            $t("strandedPersonnel.a6")
          }}</a-button>
        </template>
      </a-table>
    </div>
    <div class="footer">
      <a-button type="primary" @click="overtimeSet">{{
        $t("strandedPersonnel.a7")
      }}</a-button>
    </div>
    <!-- 超时设置 -->
    <a-modal
      :title="$t('strandedPersonnel.a7')"
      centered
      :width="300"
      :visible="visible"
      :destroyOnClose="true"
      @cancel="close"
      :getContainer="() => this.$refs.main"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="close" :disabled="disabled">
          {{ $t("strandedPersonnel.a9") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="timeSet"
          :disabled="disabled"
        >
          {{ $t("strandedPersonnel.a8") }}
        </a-button>
      </template>
      <div
        class="modal"
        v-loading="modalLoading"
        :element-loading-text="$t('strandedPersonnel.a1')"
      >
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item :label="$t('strandedPersonnel.a10')" prop="time">
            <a-time-picker
              v-model="form.time"
              :format="$t('strandedPersonnel.a11')"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import {
  doorTimetableListKT,
  overStayPersonInfo,
  retentionDeletea,
  overtimeSet,
  overRunTime,
} from "../../api/door";
import {
  tableSort,
  tableTitleDisplay,
  tableIndexDisplay,
} from "../../utils/utils";
import moment from "moment";

export default {
  name: "strandedPersonnel",
  data() {
    return {
      loading: false,
      visible: false,
      disabled: false,
      modalLoading: false,
      size: { x: 1500, y: 240 },
      form: { time: moment("04:00", "HH:mm") },
      tableDate: [],
      options: [],
      select: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 40, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("strandedPersonnel.a12")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          id: "1",
          title: "NO",
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 60,
        },
        {
          id: "2",
          title: this.$t("strandedPersonnel.a13"),
          dataIndex: "areaName",
          sorter: (a, b) => {
            return tableSort(a, b, "areaName");
          },
          width: 130,
        },
        {
          id: "3",
          title: this.$t("strandedPersonnel.a14"),
          dataIndex: "name",
          sorter: (a, b) => {
            return tableSort(a, b, "name");
          },
          width: 100,
        },
        {
          id: "4",
          title: this.$t("strandedPersonnel.a15"),
          dataIndex: "enterExitDateTime",
          sorter: (a, b) => {
            return tableSort(a, b, "enterExitDateTime");
          },
          width: 170,
        },
        {
          id: "5",
          title: this.$t("strandedPersonnel.a16"),
          dataIndex: "cardAttachNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardAttachNo");
          },
        },
        {
          id: "6",
          title: this.$t("strandedPersonnel.a17"),
          dataIndex: "stayPermitTime",
          sorter: (a, b) => {
            return tableSort(a, b, "stayPermitTime");
          },
        },
        {
          id: "7",
          title: this.$t("strandedPersonnel.a18"),
          dataIndex: "department",
          sorter: (a, b) => {
            return tableSort(a, b, "department");
          },
          width: 130,
        },
        {
          id: "8",
          title: this.$t("strandedPersonnel.a19"),
          dataIndex: "syozoku1",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku1");
          },
        },
        {
          id: "9",
          title: this.$t("strandedPersonnel.a20"),
          dataIndex: "syozoku2",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku2");
          },
        },
        {
          id: "10",
          title: this.$t("strandedPersonnel.a21"),
          dataIndex: "syozoku3",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku3");
          },
        },
        {
          id: "11",
          title: this.$t("strandedPersonnel.a22"),
          dataIndex: "syozoku4",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku4");
          },
        },
        {
          id: "12",
          title: this.$t("strandedPersonnel.a23"),
          dataIndex: "syozoku5",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku5");
          },
        },
        {
          id: "13",
          title: this.$t("strandedPersonnel.a24"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
        },
      ],
      column: [],
      displayContent:
        JSON.parse(localStorage.getItem("strandedPersonnel_tableTitle")) || [],
      rules: {
        time: [
          {
            required: true,
            message: this.$t("strandedPersonnel.a27"),
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns;
        const name = "strandedPersonnel_tableTitle";
        const data = tableIndexDisplay(name, columns, newValue);
        this.column = data;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.areaListGet();
    // 表格表头
    const name = "strandedPersonnel_tableTitle";
    const columns = this.columns;
    const data = tableTitleDisplay(name, columns);
    if (data.columns == undefined) {
      this.displayContent = data.displayContent;
    } else {
      this.column = data.columns;
      this.displayContent = data.displayContent;
    }
  },
  methods: {
    moment,
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("strandedPersonnel");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title =
          indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 118.5 - title;
        this.size.y = tableHeight;
      }
    },
    // 区域列表获取
    areaListGet() {
      this.loading = true;
      const data = {
        siteId: this.$route.query.id,
        schType: "13",
      };
      doorTimetableListKT(data)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          const { data } = res;
          this.options.push(data);
          this.select.push(data.key);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 滞留人员列表的查询方法
    inquire() {
      this.loading = true;
      const number = this.select.length - 1;
      const areaId = this.select[number].split(",")[1];
      const data = {
        siteId: this.$route.query.id,
        areaId,
        mode: number,
        refresh: true,
        sortOrders: true,
      };
      // console.log(this.select);
      // console.log(data);
      overStayPersonInfo(data)
        .then((res) => {
          console.log(res);
          this.loading = false;
          const { data } = res;
          this.tableDate = data;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 超时设置
    overtimeSet() {
      this.visible = true;
      this.modalLoading = true;
      const number = this.select.length - 1;
      const areaId = this.select[number].split(",")[1];
      const data = {
        siteId: this.$route.query.id,
        areaId,
        mode: number,
      };
      overRunTime(data)
        .then((res) => {
          console.log(res);
          this.modalLoading = false;
          const { data } = res;
          this.time = moment(`${data[0]}:${data[1]}`, "HH:mm");
        })
        .catch((err) => {
          console.log(err);
          this.modalLoading = false;
        });
    },
    // 滞留人员删除
    Delete(value) {
      this.$confirm({
        title: `${this.$t("strandedPersonnel.a25")} ${value.name} ${this.$t(
          "strandedPersonnel.a26"
        )}`,
        centered: true,
        onOk: () => {
          this.loading = true;
          const ids = [value.acsId];
          retentionDeletea(ids)
            .then((res) => {
              // console.log(res);
              this.loading = false;
              const { errorCode, msg } = res;
              if (errorCode == "00") {
                this.$message.success(msg);
                const list = this.tableDate;
                let result = list.findIndex(
                  (row) => row.cardID === value.cardID
                );
                list.splice(result, 1);
              } else {
                this.$message.error(msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        },
      });
    },
    // 超时设置设置按钮
    timeSet() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.modalLoading = true;
          this.disabled = true;
          const number = this.select.length - 1;
          const time = this.form.time;
          let data = {};
          if (number == 0) {
            data = {
              rootId: parseInt(this.select[number].split(",")[1]),
              hour: parseInt(time.format("HH")),
              minute: parseInt(time.format("mm")),
              mode: number,
              siteId: parseInt(this.$route.query.id),
            };
          } else if (number == 1) {
            data = {
              areaGrId: this.select[number].split(",")[1],
              hour: time.format("HH"),
              minute: time.format("mm"),
              mode: number,
              siteId: this.$route.query.id,
            };
          } else if (number == 2) {
            data = {
              areaId: this.select[number].split(",")[1],
              hour: time.format("HH"),
              minute: time.format("mm"),
              mode: number,
              siteId: this.$route.query.id,
            };
          }
          // console.log("data", data);
          overtimeSet(data)
            .then((res) => {
              // console.log(res);
              this.modalLoading = false;
              this.disabled = false;
              const { errorCode, msg } = res;
              if (errorCode == "00") {
                this.$message.success(msg);
              } else {
                this.$message.error(msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.modalLoading = false;
              this.disabled = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 超时设置关闭按钮
    close() {
      this.visible = false;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#strandedPersonnel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 140px;
  padding: 0 20px;
  overflow: hidden;
}
p {
  font-size: 14px;
  margin-bottom: 5px;
  color: #000000a6;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header-inquire {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 89px;
  padding: 15px 0px;
  overflow: hidden;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.main {
  height: calc(100% - 192px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.footer {
  display: flex;
  justify-content: flex-end;
  height: 52px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px #bdbcbc;
}
.time {
  margin-right: 15px;
}
.modal {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
}
</style>